import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
    englishTestList,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {loadCountryList} from "../../Masters/state/action";
import {companyCountryList} from "../../University/action";
import {
    AddLeadFxn,
    AddTransferToAnotherFxn,
    getRelatedCounsellorFxn,
} from "../action";

const TransferDrawer = (props) => {
    let {visible, onClose, userId, leadId} = props;

    const [countryList, setCountryList] = useState([]);
    const [counsellorList, setCounsellorList] = useState([]);
    const [userList, setUserList] = useState([]);
    let [leadType, setLeadType] = useState("");
    let [interestedFields, setInterestedFields] = useState([]);
    const {getFieldDecorator, getFieldValue} = props.form;
    let dispatch = useDispatch();

    const loadCounsellor = async () => {
        let obj = {};
        if (leadType == "lead") {
            obj.countryId = getFieldValue("interestedCountry");
        } else {
            obj.englishExamType = getFieldValue("interestedEnglishTest");
        }
        let x = await getRelatedCounsellorFxn({...obj, leadType});
        setUserList(x);
    };
    // const handleSubmit = (e) => {
    //     const {form} = props;
    //     e.preventDefault();
    //     form.validateFields(async (err, valData) => {
    //         if (!err) {
    //             valData.leadId = leadId;
    //             let x = await dispatch(AddTransferToAnotherFxn(valData));
    //             console.log(x);
    //         }
    //     });
    // };

    const loadCountryFxn = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    useEffect(() => {
        loadCounsellor();
        loadCountryFxn();
    }, [
        getFieldValue("interestedCountry"),
        getFieldValue("interestedEnglishTest"),
        leadType,
    ]);

    let inputTypes = {
        counsellingField: [
            {
                key: "interestedCountry",
                label: "Country",
                placeholder: "Please Choose Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interestedCountry: x,
                    });
                },
                span: "col-md-6",
            },
            {
                key: "assignTo",
                label: "Select Counsellor",
                placeholder: "Select Counsellor",
                type: "select",
                options: userList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        assignTo: x,
                    });
                },

                span: "col-md-6",
            },
        ],
        englishTestField: [
            {
                key: "interestedEnglishTest",
                label: "Select English Test",
                placeholder: "Select English Test",
                type: "select",
                options: englishTestList,
                keyAccessor: (x) => x,
                valueAccessor: (x) => `${x}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interestedEnglishTest: x,
                    });
                },
            },
            {
                key: "assignTo",
                label: "Select User",
                placeholder: "Select User",
                type: "select",
                options: userList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        assignTo: x,
                    });
                },
            },
        ],
    };

    const [showCounsellor, setShowCounsellor] = useState(true);
    const [showEnglishTest, setShowEnglishTest] = useState(true);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (leadType == "lead" && !valData.interestedCountry) {
                    notification.warning({
                        message: `Choose Interested Country`,
                    });
                    return;
                }
                if (leadType == "englishTest" && !valData.interestedEnglishTest) {
                    notification.warning({
                        message: `Choose Interested Test`,
                    });
                    return;
                }

                valData.leadId = leadId;
                valData.leadType = leadType;

                let x = await dispatch(AddTransferToAnotherFxn(valData));
                if (x && x.success) {
                    props.form.setFieldsValue({
                        interestedCountry: undefined,
                        interestedEnglishTest: undefined,
                        assignTo: undefined,
                    });
                    setInterestedFields([]);
                    setLeadType("");
                    onClose();
                }
            }
        });
    };

    const handleBack = () => {
        setLeadType("");
        setShowCounsellor(true);
        setShowEnglishTest(true);
    };
    return (
        <Drawer
            placement="right"
            onClose={onClose}
            visible={visible}
            title={"Transfer Lead"}
            width="40%"
        >
            <Form onSubmit={handleSubmit}>
                {showCounsellor ? (
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="form-group">
                                <div className="radio-check-box">
                                    <input
                                        type="checkbox"
                                        value={"lead"}
                                        name="Counselling"
                                        id="Counselling"
                                        onClick={({target}) => {
                                            setLeadType(target.value);
                                            setShowEnglishTest(false);
                                        }}
                                        checked={leadType == "lead"}
                                    />
                                    <label className="form-control">
                                        Counselling <span>Click to Select</span>
                                    </label>
                                    <img src="../app/img/check-green.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                {leadType == "lead" ? (
                                    <>
                                        {inputTypes.counsellingField.map((item, key) => {
                                            return (
                                                <div className={`col-md-12 pb-3`} key={key}>
                                                    <GetEachFormFields {...props.form} item={item}/>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {showEnglishTest ? (
                    <div className={"row"}>
                        <div className="col-lg-12 col-12">
                            <div className="form-group">
                                <div className="radio-check-box">
                                    <input
                                        type="checkbox"
                                        value={"englishTest"}
                                        name="english"
                                        id="english"
                                        onClick={({target}) => {
                                            setLeadType(target.value);
                                            setShowCounsellor(false);
                                        }}
                                        checked={leadType == "englishTest"}
                                    />
                                    <label className="form-control">
                                        Register for English Eligibility
                                        <span>Click to Select</span>
                                    </label>
                                    <img src="../app/img/check-green.svg" alt=""/>
                                </div>
                            </div>
                            <div class="col-lg-12 col-12 mt-4">
                                <div class="form-group">
                                    {leadType == "englishTest" ? (
                                        <>
                                            {inputTypes.englishTestField.map((item, key) => {
                                                return (
                                                    <div className={`col-md-12 pb-3`} key={key}>
                                                        <GetEachFormFields {...props.form} item={item}/>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div className="row">
                    <div className="col-md-12 flex-box">
                        <Button
                            type={"primary"}
                            htmlType="submit"
                            className={"btn main-btn mt-4"}
                        >
                            submit
                        </Button>
                        {leadType ? (
                            <Button
                                type="button"
                                onClick={handleBack}
                                className={"btn flat-btn mt-4"}
                            >
                                Back
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Form>
        </Drawer>
    );
};

export default Form.create()(TransferDrawer);
