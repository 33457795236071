import _, {cloneWith} from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    InputBox,
    notification,
} from "../../../components/Elements/appUtils";
import {Flags} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {branchListFxn} from "../../Reception/actions";
import {companyCountryList} from "../../University/action";
import {addUserFxn} from "../action";

const commissionOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const AddManager = (props) => {
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [countryStatus, setCountryStatus] = useState([]);
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const dispatch = useDispatch();

    useEffect(() => {
        handleState();
        loadBranches();
    }, []);
    const [isSelected, setIsSelected] = useState(false);
    const [isSelectedEnglish, setIsSelectedEnglish] = useState(false);

    useEffect(() => {
        props.form.setFieldsValue({
            countryCode: "IN_+91",
        });
    }, []);

    const handleState = async () => {
        let x = await companyCountryList();
        console.log(x.data);
        setCountryList(x.data);
    };
    const loadBranches = async () => {
        let {data} = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data);
        setTimeout(() => {
            let headBranch = _.find(data, (item) => {
                return item.userType == "headBranch";
            });
            console.log(headBranch, "headdddddddddddd");
            props.form.setFieldsValue({
                branchId: headBranch._id,
            });
        }, 700);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                /*  let countryStatusList = [];
                                          _.each(countryStatus, (item) => {
                                              if (item) {
                                                  countryStatusList.push({countryId: item});
                                              }
                                          });
                                          delete valData["countryId"];
                                          valData.countryStatusList = countryStatusList;
                                          _.each(countryStatus, (item) => {
                                              if (item) {
                                                  countryStatusList.push({countryId: item});
                                              }
                                          });
                                          valData.countryStatusList = countryStatusList;*/

                if (!valData.name) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }
                if (!valData.password) {
                    notification.warning({message: "Enter password"});
                    return;
                }
                if (valData.password.length < 6) {
                    notification.warning({message: "Enter atleast 6 digit password."});
                    return;
                }
                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }
                // if (user.userType == "headBranch") {
                //     if (!valData.branchId) {
                //         notification.warning({message: "Choose branch"})
                //         return
                //     }
                // }

                let x = await dispatch(addUserFxn({...valData, userType: "manager"}));
                if (x.success) {
                    props.form.setFieldsValue({
                        name: "",
                        email: "",
                        mobile: "",
                        password: "",
                        address: "",
                        countryCode: "",
                        branchId: "",
                        enableForCommission: "",
                        enableForIp: "",

                        countryId: [],
                    });
                }
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: " Full Name",
                required: false,
                placeholder: "Name",
                type: "text",
                showStar: true,
            },
            {
                key: "email",
                label: "Email",
                required: false,
                placeholder: "Email",
                type: "text",
                showStar: true,
            },
            {
                key: "password",
                label: "Password",
                required: false,
                placeholder: "Password",
                type: "text",
                showStar: true,
            },
            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "enableForCommission",
                label: "Enable For Commission",
                options: commissionOptions,
                type: "select",
                required: false,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        enableForCommission: x,
                    });
                },
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                showStar: true,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-3",
            },
            {
                key: "mobile",
                label: "Mobile No",
                required: false,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-3",
                showStar: true,
            },

            {
                key: "branchId",
                label: "Branch",
                required: false,
                hidden: !(user.userType == "headBranch"),
                placeholder: "Please Choose Branch",
                type: "select",
                showStar: true,
                options: branchList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) =>
                    `${
                        x && x.userType && x.userType === "branch"
                            ? `${x.cityName ? x.cityName : ""}(${x.contactPerson})`
                            : `${x.cityName ? x.cityName : ""}(${x.name})`
                    }`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        branchId: x,
                    });
                },
            },
            {
                key: "enableForIp",
                label: "Enable For Ip Address",
                placeholder: "Enable For Ip Address",
                options: RegistrationOptions,
                type: "select",
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        enableForIp: x,
                    });
                },
            },
            // {
            //   key: "countryId",
            //   label: "Country",
            //   required: true,
            //   placeholder: "Country",
            //   multiple: true,
            //   type: "select",
            //   showSearch: true,
            //   options: countryList,
            //   keyAccessor: (x) => x._id,
            //   valueAccessor: (x) => `${x.countryName}`,
            //   onChange: (x) => {
            //     setCountryStatus(x);
            //     props.form.setFieldsValue({
            //       countryId: x,
            //     });
            //   },
            // },
        ],
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Add Manager"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    {" "}
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Card>
            </Form>
        </>
    );
};

export default Form.create()(AddManager);
