import _ from "lodash";
import React, {Suspense, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams } from "react-router-dom";
import DateTimePicker from "../../../components/Elements/DatePicker";
import {
    Card,
    DatePicker,
    Form,
    HyperLink,
    Popconfirm,
    Table,
    Tooltip,
    displayDate,
} from "../../../components/Elements/appUtils";
import {
    getAvatar,
    imgPath,
    statusOption,
} from "../../../components/_utils/_utils";
import {branchListFxn} from "../../Counsellor/action";
import LeadSlider from "../../Dashboard/components/leadSlider";
import {AddCloseFxn, AddDeleteFxn, LeadListFxn} from "../action";
import AddFollowup from "../drawers/AddFollowup";
import EditFollowup from "../drawers/EditFollowup";
import TransferDrawer from "../drawers/TransferDrawer";
import CloseLead from "../drawers/closeLead";
import ConvertToEnglishTest from "../drawers/convertToEnglishTest";
import EditLead from "../drawers/editLead";
import FollowUpList from "../drawers/followUpList";
import OpenLead from "../drawers/openLead";
import LeadCountSlider from "../leadCountSlider";

let {TableCard} = Card;
let initState = {
    visible: false,
    leadId: "",
};
let initFilter = {
    status: "Pending",
    email: "",
    name: "",
    mobileNo: "",
    dateOfBirth: null,
    countryCode: "",
};
const LeadList = (props) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "../app/js/customScript.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [open, setOpen] = useState(false);
    const [closeLeadState, setCloseLeadState] = useState(initState);
    const [OpenLeadState, setOpenLeadState] = useState(initState);
    const [transferClose, setTransferClose] = useState(false);
    const [filterState, setFilterState] = useState(initFilter);
    const [branchFilter, setBranchFilter] = useState("");
    const [visibleFollowUps, setVisibleFollowUps] = useState(false);
    const [total, setTotal] = useState([]);
    const [assignId, setAssignId] = useState("");
    const [leadId, setLeadId] = useState("");
    const [branchList, setBranchList] = useState([]);
    let [followupState, setFollowUpState] = useState({
        visible: false,
        lead: {},
    });
    let [editState, setEditState] = useState(initState);
    let [editFollowState, setEditFollowState] = useState({
        followUpId: "",
        visible: false,
    });

    let [convertToTestState, setConvertToTestState] = useState(initState);

    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

        useEffect(() => {
            if (branchFilter !== "") {
                tableRef.current.reload({ branchId: branchFilter });
            }
        }, [branchFilter]);
    const events = {
        showFollowUp: (lead) => {
            setFollowUpState({
                visible: true,
                lead: lead,
            });
        },
        hideFollowUp: () => {
            setFollowUpState({
                visible: false,
                lead: {},
            });
            events.reloadTable();
        },
        showTransfer: (id) => {
            setTransferClose(true);
            setAssignId(id);
            setLeadId(id);
        },
        onTransferClose: () => {
            setTransferClose(false);
        },
        handleDelete: (id) => {
            const {form} = props;
            form.validateFields(async (err, valData) => {
                if (!err) {
                    valData.leadId = id;
                    let x = await dispatch(AddDeleteFxn(valData));
                }
            });
        },
        showClose: (id) => {
            setCloseLeadState({
                leadId: id,
                visible: true,
            });
        },
        hideClose: () => {
            setCloseLeadState({
                leadId: "",
                visible: false,
            });
            events.reloadTable();
        },
        showOpenLead: (id) => {
            setOpenLeadState({
                leadId: id,
                visible: true,
            });
        },
        hideOpenLead: () => {
            setOpenLeadState({
                leadId: "",
                visible: false,
            });
            events.reloadTable();
        },
        showFollowUpList: (id) => {
            setVisibleFollowUps(true);
            setLeadId(id);
        },
        hideFollowUpList: (id) => {
            setVisibleFollowUps(false);
            setLeadId(id);
        },
        showEditLead: (id) => {
            setEditState({
                leadId: id,
                visible: true,
            });
        },
        hideEditLead: () => {
            setEditState({
                leadId: "",
                visible: false,
            });
        },
        hideEditLeadAndUpdate: () => {
            setEditState({
                leadId: "",
                visible: false,
            });
            events.reloadTable();
        },
        showEditFollowUp: (id) => {
            setEditFollowState({
                followUpId: id,
                visible: true,
            });
        },
        hideEditFollowUp: () => {
            setEditFollowState({
                followUpId: "",
                visible: false,
            });
        },
        showConvertToTest: (id) => {
            setConvertToTestState({
                leadId: id,
                visible: true,
            });
        },
        hideConvertToTest: () => {
            setConvertToTestState({
                leadId: "",
                visible: false,
            });
        },
        reloadTable: () => {
            tableRef.current.reload();
        },
        updateFilter: (data) => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        filterFxn: () => {
            tableRef.current.reload();
        },
        clearFilter: () => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...initFilter,
                };
            });
            setTimeout(() => {
                tableRef.current.reload();
            }, 500);
        },
        handleBranchFilter: async (branchId) => {
            console.log(branchId, "brrro--")
            setBranchFilter(branchId);
            await tableRef.current.reload({ branchId });
        },
    };

    const onTransferClose = () => {
        setTransferClose(false);
        events.reloadTable();
    };

    let tableRef = useRef();
    const apiRequest = async (params) => {
        console.log('User:', user);
        console.log('Params:', params);
    
        const defaultBranchId = user && user.addedBy ? user.addedBy : "";
        params.branchId = params.branchId || defaultBranchId;
    
        // If user._id is available and branchFilter is not provided, filter by user._id
        if (user && user._id && !params.branchFilter) {
            params.addedBy = user._id;
        }
    
        if (params.branchFilter && !params.branchId) {
            // If branchFilter is provided and branchId is not already set, filter by it
            params.branchId = params.branchFilter;
        }
    
        if (filterState.name) {
            params.name = filterState.name;
        }
        if (filterState.email) {
            params.email = filterState.email;
        }
        if (filterState.status) {
            params.status = filterState.status;
        }
        if (filterState.mobileNo) {
            params.mobileNo = filterState.mobileNo;
        }
    
        console.log("Before dispatch: params", params);
    
        try {
            let data = await dispatch(
                LeadListFxn({
                    ...params,
                    addedBy: user._id, 
                    branchId: params.branchFilter, 
                    regExFilters: ["name", "email"],
                })
            );
    
            console.log("After dispatch: data", data, "branchid", params);
            setTotal(data.total);
            return data;
        } catch (error) {
            console.error("Error in API request:", error);
            return { error: true, message: "Error in API request" };
        }
    };
    
    

    useEffect(() => {
        const loadBranches = async () => {
            try {
                let data = await dispatch(
                    branchListFxn({
                        userType: ["branch", "headBranch"],
                    })
                );
                
                setBranchList(data.data);
    
                if (data.data.length === 1) {
                    await events.handleBranchFilter(data.data[0]._id);
                }
            } catch (error) {
                console.error("Error loading branches:", error);
            }
        };
    
        loadBranches();
    }, []);
    useEffect(() => {
    
        const sendEmailOnStatusChange = async () => {
            try {
                const response = await fetch("/sendEmailOnStatusChange", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.id, 
                        leadId: leadId,
                        newStatus: filterState.status,
                    }),
                });
                 console.log(response, "-----------")
                if (response.ok) {
                    console.log("Email sent successfully");
                } else {
                    console.error("Failed to send email");
                }
            } catch (error) {
                console.error("Error sending email:", error);
            }
        };

   
        if (filterState.status !== initFilter.status) {
            sendEmailOnStatusChange();
        }
    }, [filterState.status, leadId, user.id, branchFilter]);

    const loadBranches = async () => {
        let data = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data.data);
    };

    let openLink = (link) => {
        navigate(link);
    };

    let columns = [
        {
            title: "",
            key: "interestedCountry",
            dataIndex: "interestedCountry",
            render: (item, record) => {
                return (
                    <div>
                        {item && item.flag && item.flag.path ? (
                            <img
                                src={item.flag.path}
                                alt=""
                                height="50"
                                width="50"
                                style={{borderRadius: "50%"}}
                            />
                        ) : (
                            <img src={"../app/img/ILETS.svg"} alt="" width="50"/>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            render: (item, record) => {
                return <div style={{width: 100}}>{item}</div>;
            },
        },
        {
            title: "DOB",
            dataIndex: "dateOfBirth",
            key: "dateOfBirth",
            render: (item, record) => {
                return <>{item ? displayDate(item) : ""}</>;
            },
        },
        {
            title: "State",
            dataIndex: "stateName",
            key: "stateName",
        },
        {
            title: "City",
            dataIndex: "cityName",
            key: "cityName",
        },
        {
            title: "Mobile",
            dataIndex: "mobileNo",
            key: "mobileNo",
            render: (item, record) => {
              
              const countryCode = record.countryCode.substring(3)
              return <>{`${countryCode}-${item}`}</>;
            },
          },
        {
            title: "Interested",
            dataIndex: "interested",
            key: "interested",
            render: (item, record) => {
                let {interestedEnglishTest, interestedCountry, leadType} = record;
                return (
                    <>
                        {leadType == "englishTest" ? `${interestedEnglishTest}` : ""}
                        {leadType == "lead" &&
                        interestedCountry &&
                        interestedCountry.countryName
                            ? `${interestedCountry.countryName}`
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Assigned To",
            dataIndex: "assignTo",
            key: "assignTo",
            render: (item, record) => {
                return <>{item && item.name ? item.name : ""}</>;
            },
        },
        {
            title: "Added By",
            dataIndex: "addedBy",
            key: "addedBy",
            render: (item, record) => {
                return <>{item && item.name ? item.name : ""}</>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (item, record) => {
                return (
                    <>
                        {item == "Pending" ? <span className="pending">Pending</span> : ""}
                        {item == "Done" ? <span className="active">Done</span> : ""}
                        {item == "Closed" ? <span className="inactive">Closed</span> : ""}
                    </>
                );
            },
        },
        {
            title: "Added On",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item, record) => {
                return <>{item ? displayDate(item) : ""}</>;
            },
        },
        {
            title: "Follow ups",
            dataIndex: "followUps",
            key: "followUps",
            hidden: user.userType == "reception",

            render: (v, item) => {
                return (
                    <>
                        {item.status !== "Closed" && (
                            <a
                                className={"btn btn-link btn-xs mr-1"}
                                onClick={() => events.showFollowUp(item)}
                            >
                                View & Add
                            </a>
                        )}
                    </>
                );
            },
        },

        /*   {
                                   title: "Lead Type",
                                   dataIndex: "leadType",
                                   key: "leadType",
                                   render: (item, record) => {
                                       return (
                                           <>
                                            <span className="active">
                                                   {item == 'englishTest' ? "English Test" : "Lead"}
                                               </span>
                                           </>
                                       );
                                   },
                               },*/

        {
            title: "Lead Source",
            dataIndex: "leadSource",
            key: "leadSource",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            hidden: user.userType == "reception",
            render: (v, item) => {
                let {status, extraServices} = item;
                return (
                    <>
                        {status == "Pending" ? (
                            <>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Transfer To Other"
                                    onClick={() => events.showTransfer(item._id)}
                                >
                                    <img src={imgPath.transfer} alt=""/>
                                </button>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Close Lead"
                                    onClick={() => events.showClose(item._id)}
                                >
                                    <img src={imgPath.close} alt=""/>
                                </button>
                            </>
                        ) : null}{" "}
                        {status == "Closed" ? (
                            <>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Open Lead"
                                    onClick={() => events.showOpenLead(item._id)}
                                >
                                    <img src={imgPath.check} alt=""/>
                                </button>
                            </>
                        ) : null}
                        <button
                            className="btn"
                            type="button"
                            title="View Lead Details"
                            onClick={() => {
                                openLink(`/lead-Profile?_id=${item._id}`);
                            }}
                        >
                            <img src={imgPath.view} alt=""/>
                        </button>
                        {status == "Pending" ? (
                            <>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Edit Lead"
                                    onClick={() => {
                                        openLink(`/edit-Lead?_id=${item._id}`);
                                    }}
                                >
                                    <img src={imgPath.edit} alt=""/>
                                </button>

                                <button
                                    className="btn"
                                    type="button"
                                    title="Convert Student"
                                    onClick={() => {
                                        openLink(`/add-student?_id=${item._id}`);
                                    }}
                                >
                                    <img src={imgPath.transfer} alt=""/>
                                </button>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Payment"
                                    onClick={() => {
                                        openLink(`/add-payment-details?_id=${item._id}`);
                                    }}
                                >
                                    <img src={imgPath.wallet} alt=""/>
                                </button>

                                {extraServices ? (
                                    <button
                                        className="btn"
                                        type="button"
                                        title="Payment Details"
                                        onClick={() => {
                                            openLink(`/agency-fees-details/${item._id}`);
                                        }}
                                    >
                                        <img src={imgPath.view} alt=""/>
                                    </button>
                                ) : null}
                            </>
                        ) : null}
                    </>
                );
            },
        },
    ];

    let loadColumns = () => {
        let columnList = [];
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item);
            }
        });
        return columnList;
    };

    let filterTypes = {
        fields: [
            {
                key: "status",
                label: "Status",
                required: true,
                placeholder: "status",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: statusOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        status: x,
                    });

                    setFilterState(x);
                },
                span: "col-md-4",
            },
            {
                key: "branch",
                label: "Branch",
                required: true,
                placeholder: "Branch",
                type: "select",
                options: branchList,
                hidden: !(user.userType == "headBranch"),
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        branch: x,
                    });
                    setBranchFilter(x);
                },
            },
        ],
    };

    return (
        <>
            <LeadSlider  {...props}
                callback={(selectedBranchId) => {
                    console.log("Selected Branch ID in LeadList:", selectedBranchId);
                    events.handleBranchFilter(selectedBranchId);
                }}/>

            <TableCard
                title={`Walk-in List (${total})`}
                extraBtn={[
                    {
                        name: "Add Walk-in",
                        action: () => {
                            navigate("/add-Lead");
                        },
                    },
                ]}
            >
                {/*<Form onSubmit="">
                    <div className={"row"}>
                        {filterTypes.fields.map((item, key) => {
                            return (
                                <div className="col-md-2" key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Form>*/}

                <div className="filter px-4 mb-4 mt-4">
                    <form>
                        {/* <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Company Name"
                                className="form-control"
                            />
                        </div>*/}
                        <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Contact Person"
                                className="form-control"
                                value={filterState.name}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        name: target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Email"
                                className="form-control"
                                value={filterState.email}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        email: target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Mobile No"
                                className="form-control"
                                value={filterState.mobileNo}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        mobileNo: target.value,
                                    });
                                }}
                            />
                        </div>
                        {/* <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                                <DatePicker
                                    value={filterState.dateOfBirth}
                                    className="form-control"
                                    placeholder="Date Of Birth"
                                    onChange={(target) => {
                                        events.updateFilter({
                                            dateOfBirth: target,
                                        });
                                    }}
                                />
                        </div>*/}
                        {/*  <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Contact Person"
                                className="form-control"
                            />
                        </div>*/}
                        <div className="form-group select-box">
                            <select
                                name="plan"
                                id="plan"
                                className="form-control"
                                value={filterState.status}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        status: target.value,
                                    });
                                }}
                            >
                                <option value={""}>Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Converted">Converted</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>

                        <a className="btn blue-btn" onClick={events.filterFxn}>
                            Search
                        </a>
                        <a className="btn flat-btn" onClick={events.clearFilter}>
                            Clear
                        </a>
                    </form>
                </div>

                <Table
                apiRequest={apiRequest}
                columns={loadColumns()}
                ref={tableRef}
                extraProps={{ scroll: { x: 1500 } }}
                />
            </TableCard>

            {followupState.visible ? (
                <AddFollowup
                    onClose={events.hideFollowUp}
                    onSubmit={() => {
                        events.hideFollowUp();
                        events.reloadTable();
                    }}
                    visible={followupState.visible}
                    lead={followupState.lead}
                />
            ) : null}
            {transferClose ? (
                <TransferDrawer
                    onClose={onTransferClose}
                    visible={transferClose}
                    userId={assignId}
                    leadId={leadId}
                />
            ) : null}
            {leadId && visibleFollowUps ? (
                <FollowUpList
                    onClose={events.hideFollowUpList}
                    visible={visibleFollowUps}
                    leadId={leadId}
                />
            ) : (
                ""
            )}

            {closeLeadState.visible ? (
                <CloseLead
                    onClose={events.hideClose}
                    visible={closeLeadState.visible}
                    leadId={closeLeadState.leadId}
                />
            ) : null}
            {OpenLeadState.visible ? (
                <OpenLead
                    onClose={events.hideOpenLead}
                    visible={OpenLeadState.visible}
                    leadId={OpenLeadState.leadId}
                />
            ) : null}

            {editState.visible ? (
                <EditLead
                    onClose={events.hideEditLead}
                    onSubmit={events.hideEditLeadAndUpdate}
                    visible={editState.visible}
                    leadId={editState.leadId}
                />
            ) : (
                ""
            )}
            {convertToTestState.visible ? (
                <ConvertToEnglishTest
                    onClose={events.hideConvertToTest}
                    onSubmit={() => {
                        events.hideConvertToTest();
                    }}
                    visible={convertToTestState.visible}
                    leadId={convertToTestState.leadId}
                />
            ) : null}

            {editFollowState.visible ? (
                <EditFollowup
                    onClose={events.hideEditFollowUp}
                    onSubmit={() => {
                        events.hideEditFollowUp();
                        events.reloadTable();
                    }}
                    visible={editFollowState.visible}
                    followUpId={editFollowState.followUpId}
                />
            ) : null}
        </>
    );
};

export default Form.create()(LeadList);
