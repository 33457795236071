import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    Modal,
    notification,
} from "../../../components/Elements/appUtils";
import {
    EnglishExamOptions,
    dateFormats,
    genderOption,
    timeFormats,
} from "../../../components/_utils/_utils";
import {
    Countries,
    CurrencyJson,
    Flags,
    TimeZones,
} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import EducationComponent from "../../AddStudent/Components/educationComponent";
import {companyByIdFxn, updateCompanyFxn} from "../../Masters/company/action";
import {companyCountryList} from "../../University/action";
import {GetRelatedUserFxn} from "../../Users/action";
import {postUpdateUserFxn} from "../../Users/views/action";

const {InnerCard} = Card;
const eduStateInit = {
    qualification: "",
    math: "",
    english: "",
    percentage: "",
    board: "",
    university: "",
    stream: "",
    passingYear: "",
    courseName: "",
    schoolName: "",
};
const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const EditCompanyProfile = (props) => {
    let {currentUser} = props;
    const initialState = {
        name: "",
        passportNumber: "",
        email: "",
        address: "",
        companyContactNo: "",
        companyAddress: "",
        companyEmail: "",
        companyLogo: {},
        country: undefined,
        gstRegistration: true,
        gstInNo: "",
        pincode: "",
        countryCode: "",
        cityName: undefined,
        countryName: undefined,
        stateName: undefined,
        postalCode: "",
        agentId: "",
        certificate: {},
        profilePhoto: {},
        referBy: "",
    };

    const [countryList, setCountryList] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [userId, setUserId] = useState("");
    let [extraFields, setExtraFields] = useState({});
    let [formState, setState] = useState(initialState);
    const [education, setEducation] = useState([eduStateInit]);
    const [enableForEnglish, setEnableForEnglish] = useState(false);
    const dispatch = useDispatch();
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    useEffect(() => {
        handleState();
    }, []);
    useEffect(() => {
        let id = user._id;
        if (id) {
            setUserId(id);
            getRelatedUser(id);
        }
    }, []);

    const getRelatedUser = async (id) => {
        let {data} = await dispatch(companyByIdFxn());
        setTimeout(() => {
            props.form.setFieldsValue({
                contactPerson: data.contactPerson,
                companyName: data.companyName,
                address: data.address,
                email: data.email,
                countryCode: data.countryISOCode,
                mobile: data.mobile,
                currency: data.currency,
                timezone: data.timezone,
                timeFormat: data.timeFormat,
                dateFormat: data.dateFormat,
                postalCode: data.postalCode,

                countryName: data.countryName,
                stateName: data.stateName,
                cityName: data.cityName,
                enableForIp: data.enableForIp,
            });
        }, 500);
    };

    const handleState = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.contactPerson) {
                    notification.warning({message: "Enter name"});
                    return;
                }

                if (!valData.companyName) {
                    notification.warning({message: "Enter company name"});
                    return;
                }

                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }

                valData.companyId = currentUser.companyId;

                let x = await dispatch(updateCompanyFxn({...valData}));
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };
    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
        updateExtraState: (data) => {
            setExtraFields((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
    };

    let inputTypes = {
        fields: [
            {
                key: "contactPerson",
                label: "Contact Person",
                required: false,
                placeholder: "Contact Person",
                type: "text",
                showStar: true,
            },

            {
                key: "companyName",
                label: "Company Name",
                required: false,
                placeholder: "Company Name",
                type: "text",
                showStar: true,
            },

            {
                key: "email",
                label: "Contact Email",
                required: true,
                placeholder: "Contact Email",
                type: "text",
                disabled: true,
                showStar: true,
            },

            {
                key: "currency",
                label: "Currency",
                placeholder: "Choose Currency",
                type: "select",
                required: false,
                options: CurrencyJson,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => x.name,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        currency: x,
                    });
                },
            },
            {
                key: "countryCode",
                placeholder: "Country Code",
                span: "col-md-2",
                type: "select",
                required: false,
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                showStar: true,
            },
            {
                key: "mobile",
                span: "col-md-4",
                label: "Contact Number",
                required: false,
                placeholder: "Contact Number",
                type: "text",
                showStar: true,
            },
            //   {
            //     key: "timezone",
            //     label: "Timezone",
            //     type: "select",
            //     required: false,
            //     options: TimeZones,
            //     showSearch: true,
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => `${x.name}`,
            //     onChange: (x) => {
            //       props.form.setFieldsValue({
            //         timezone: x,
            //       });
            //     },
            //   },
            //   {
            //     key: "dateFormat",
            //     label: "Date Format",
            //     type: "select",
            //     required: false,
            //     options: dateFormats,
            //     showSearch: true,
            //     keyAccessor: (x) => x,
            //     valueAccessor: (x) => `${x}`,
            //     onChange: (x) => {
            //       props.form.setFieldsValue({
            //         dateFormat: x,
            //       });
            //     },
            //   },

            //   {
            //     key: "timeFormat",
            //     label: "Time Format",
            //     type: "select",
            //     required: false,
            //     options: timeFormats,
            //     showSearch: true,
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => x.name,
            //     onChange: (x) => {
            //       props.form.setFieldsValue({
            //         timeFormat: x,
            //       });
            //     },
            //   },
            {
                key: "website",
                label: "Website",
                placeholder: "Website(Optional)",
                type: "text",
            },
            {
                key: "countryName",
                label: "Country",
                required: false,
                type: "select",
                showSearch: true,
                allowClear: true,
                options: Countries,
                placeholder: "Country ",
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
            },

            {
                key: "stateName",
                label: "State",
                required: false,
                type: "select",
                span: "col-md-2",
                showSearch: true,
                allowClear: true,
                placeholder: "State Name",
                options: stateArray,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateName: x,
                        cityName: "",
                    });
                    events.chooseState(x);
                },
            },
            {
                key: "postalCode",
                label: "Postal Code (Optional)",
                span: "col-md-4",
                required: false,
                placeholder: "Postal Code",
                type: "text",
            },

            {
                key: "companyAddress",
                label: "Company Address",
                required: false,
                placeholder: "Company Address",
                type: "text",
            },
            {
                key: "enableForIp",
                label: "Enable For Ip Address",
                placeholder: "Enable For Ip Address",
                options: RegistrationOptions,
                type: "select",
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        enableForIp: x,
                    });
                },
            },
        ],
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Edit Profile"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Card>
            </Form>
        </>
    );
};

export default Form.create()(EditCompanyProfile);
