import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    TableButton,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {changepasswordFxn} from "./action";

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const handleSubmitPasswordChange = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.oldPassword) {
                    notification.warning({message: "Enter your current password"});
                    return;
                }
                if (!valData.newPassword) {
                    notification.warning({message: "Enter your new password"});
                    return;
                }
                if (valData.newPassword !== valData.confirmPassword) {
                    notification.warning({
                        message: "New password and confirm password do not match",
                    });
                    return;
                }
                valData.password = valData.oldPassword;
                valData.password = valData.confirmPassword;

                let x = await dispatch(changepasswordFxn(valData));
                if (x && x.success) {
                    form.resetFields();
                    props.form.setFieldsValue({
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    });
                    if (props && props.onClose) {
                        props.onClose();
                    }
                }
            } else {
                notification.warning({message: "Please Fill All Required Fields"});
            }
        });
    };
    let inputTypes = {
        changePassword: [
            {
                key: "oldPassword",
                label: "Current Password",
                type: "password",
                placeholder: "Enter your current password",
                span: "col-md-12",
            },
            {
                key: "newPassword",
                label: "New Password",
                type: "password",
                placeholder: "Enter your new password",
                span: "col-md-12",
            },
            {
                key: "confirmPassword",
                label: "Confirm Password",
                type: "password",
                placeholder: "Confirm your new password",
                span: "col-md-12",
            },
        ],
    };
    return (
        <>
            <Card title={"Change Password"}>
                <Form onSubmit={handleSubmitPasswordChange}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"row"}>
                                {inputTypes.changePassword.map((item, key) => {
                                    return (
                                        <div
                                            className={` ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop: "10px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default Form.create()(ChangePassword);
