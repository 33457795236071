import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Card,
    HyperLink,
    InputBox,
    Modal,
    Popconfirm,
    Table,
    displayDate,
    longDisplayDate,
    notification,
} from "../../../components/Elements/appUtils";
import {imgPath} from "../../../components/_utils/_utils";
import {
    approvedTrialManualFxn,
    companyListFxn,
    deleteCompanyFxn,
    sendOptToDeleteCompanyFxn,
} from "./action";

let {TableCard} = Card;
const CompanyList = (props) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let [total, setTotal] = useState(0);
    let [visibleOtpScreen, setVisibleOtpScreen] = useState(false);
    let [companyToDelete, setCompanyToDelete] = useState("");
    let [otp, setOtp] = useState("");

    let tableRef = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await companyListFxn({
                ...params,
                companyRegistered: true,
                regExFilters: ["contactPerson", "email"],
            });
            setTotal(data.total);
            resolve(data);
        });
    };

    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    let events = {
        extendTrial: async (id) => {
            let resp = await dispatch(approvedTrialManualFxn({companyId: id}));
            if (resp && resp.success) {
                tableRef.current.reload();
            }
        },
        sendOptToDelete: async (id) => {
            let resp = await dispatch(sendOptToDeleteCompanyFxn({companyId: id}));
            if (resp && resp.success) {
                setCompanyToDelete(id);

                setVisibleOtpScreen(true);
            }
        },
        submitHandleOtp: async (e) => {
            if (!otp) {
                notification.warning({
                    message: "Please enter Otp",
                });
                return;
            }
            let obj = {};
            obj.otp = otp;
            obj.companyId = companyToDelete;
            console.log(obj);
            let x = await dispatch(deleteCompanyFxn(obj));
            if (x.success) {
                setVisibleOtpScreen(false);
                setCompanyToDelete("");
        
                tableRef.current.reload();
                setOtp("")
            }
        },        
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "contactPerson",
            key: "contactPerson",
            searchTextName: "contactPerson",
            filterRegex: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            searchTextName: "email",
            filterRegex: true,
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "State",
            dataIndex: "stateName",
            key: "stateName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "Contact No",
            dataIndex: "mobile",
            key: "mobile",
            render: (item, record) => {
                let {countryCode} = record;
                return `${countryCode} ${item}`;
            },
        },
        {
            title: "Last LoginTime",
            key: "lastLoginTime",
            dataIndex: "lastLoginTime",
            render: (item) => {
                return item ? longDisplayDate(item) : "";
            },
        },
        {
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (item, record) => {
                return (
                    <>
                        {item ? (
                            <span className="active">Active</span>
                        ) : (
                            <span className="inactive">Inactive</span>
                        )}
                    </>
                );
            },
        },
        // {
        //     title: "Company Registered",
        //     dataIndex: "companyRegistered",
        //     key: "companyRegistered",
        //     render: (item, record) => {
        //         return (
        //             <>
        //                 {item ? (
        //                     <span className="active">Registered</span>
        //                 ) : (
        //                     <span className="inactive">Not-Registered</span>
        //                 )}
        //             </>
        //         );
        //     },
        // },
        {
            title: "No. of Trials",
            dataIndex: "noOfTrial",
            key: "noOfTrial",
            render: (item, record) => {
                return item ? item : 0;
            },
        },
        {
            title: "Total Leads",
            dataIndex: "totalLeadCount",
            key: "totalLeadCount",
            render: (item, record) => {
                return item ? item : 0;
            },
        },
        {
            title: "Total Students",
            dataIndex: "totalStudentCount",
            key: "totalStudentCount",
            render: (item, record) => {
                return item ? item : 0;
            },
        },
        {
            title: "Total GicRequests",
            dataIndex: "totalGicRequest",
            key: "totalGicRequest",
            render: (item, record) => {
                return item ? item : 0;
            },
        },
        {
            title: "Total Branches",
            dataIndex: "totalBranch",
            key: "totalBranch",
            render: (item, record) => {
                return item ? item : 0;
            },
        },
        {
            title: "Action",
            key: "_id",
            dataIndex: "_id",
            render: (item, record) => {
                return (
                    <>
                        <button
                            className={"btn"}
                            onClick={() => {
                                navigate(`/master/edit-company?_id=${item}`);
                            }}
                        >
                            <img src="../app/img/icons/edit.svg"/>
                        </button>
                        <Popconfirm
                            title={"Are you sure, you want to extend the Trial?"}
                            onConfirm={() => {
                                events.extendTrial(record._id);
                            }}
                        >
                            <button className={"btn"}>
                                <img src={imgPath.check}/>
                            </button>
                        </Popconfirm>
                        {user && user.userType == "admin" ? (
                            <Popconfirm
                                title="Are you sure to delete this Company?"
                                onConfirm={() => {
                                    events.sendOptToDelete(record._id);
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button className={"btn"}>
                                    <img src={imgPath.close}/>
                                </button>
                            </Popconfirm>
                        ) : null}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <TableCard
                {...props}
                title={`Total ${total} Companies`}
                extraBtn={[
                    {
                        name: "Add Company",
                        action: () => {
                            navigate("/master/add-company");
                        },
                    },
                ]}
            >
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    extraProps={{scroll: {x: 2000}}}
                    ref={tableRef}
                />
            </TableCard>
            {visibleOtpScreen ? (
                <Modal
                    visible={visibleOtpScreen}
                    closable={false}
                    title={"Enter Otp To Confirm"}
                    footer={false}
                >
                    <div style={{textAlign: "center", marginBottom: 10}}>
                        <h5>
                            A text message with a 5-digit verification code has been sent to
                            your phone number.
                        </h5>
                        <InputBox title={"Enter OTP"}>
                            <input
                                className={"form-control mt10"}
                                type={"password"}
                                minLength={5}
                                maxLength={5}
                                value={otp}
                                onChange={({target}) => {
                                    setOtp(target.value);
                                }}
                            />
                        </InputBox>
                        <Button
                            className={"btn btn-success mt10"}
                            onClick={events.submitHandleOtp}
                        >
                            Submit OTP
                        </Button>
                    </div>
                </Modal>
            ) : null}
        </>
    );
};

export default CompanyList;
