import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../../components/Elements/appUtils";
import {dateFormats, timeFormats} from "../../../components/_utils/_utils";
import {
    Countries,
    CurrencyJson,
    Flags,
    TimeZones,
} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddBranchFxn} from "../action";

const initialState = {
    companyName: "",
    contactPerson: "",
    countryCode: "",
    mobile: "",
    companyAddress: "",
    email: "",
    companyLogo: {},
    country: undefined,
    gstRegistration: true,
    gstInNo: "",
    cityName: undefined,
    countryName: undefined,
    stateName: undefined,
    postalCode: "",
    agentId: "",
    certificate: {},
    profilePhoto: {},
    referBy: "",
    assigntype: "",
};

const AddBranch = (props) => {
    let dispatch = useDispatch();
    const {getFieldDecorator, getFieldValue} = props.form;
    let [formState, setState] = useState(initialState);
    const [stateArray, setStateArray] = useState([]);
    const setLocalState = (data) => {
        setState({
            ...formState,
            ...data,
        });
    };
    useEffect(() => {
        props.form.setFieldsValue({
            currency: "INR",
            countryCode: "IN_+91",
            countryName: "India",
        });
        events.chooseCountry("India");
    }, []);
    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            let {certificate, companyLogo} = formState;
            if (!err) {
                /*if (!valData.companyName) {
                                                    notification.warning({
                                                        message: `Please enter  Company Name`,
                                                    });
                                                    return;
                                                }*/

                if (!valData.contactPerson) {
                    notification.warning({
                        message: `Please enter contact person name`,
                    });
                    return;
                }
                if (!valData.countryName) {
                    notification.warning({
                        message: `Choose country`,
                    });
                    return;
                }
                if (!valData.currency) {
                    notification.warning({
                        message: `Please choose currency.`,
                    });
                    return;
                }
                if (!valData.stateName) {
                    notification.warning({
                        message: `Please Select State`,
                    });
                    return;
                }
                if (!valData.cityName) {
                    notification.warning({
                        message: `Please Enter city`,
                    });
                    return;
                }

                /*  if (!valData.assigntype) {
                                                      notification.warning({
                                                          message: `Please choose assign type`,
                                                      });
                                                      return;
                                                  }*/
                if (!valData.postalCode) {
                    notification.warning({
                        message: `Please Enter Postal Code`,
                    });
                    return;
                }
                // if (!valData.countryCode) {
                //   notification.warning({
                //     message: `Choose country code`,
                //   });
                //   return;
                // }
                // if (!valData.mobile) {
                //   notification.warning({
                //     message: `Please enter contact no`,
                //   });
                //   return;
                // }

                // if (!valData.countryName) {
                //   notification.warning({
                //     message: `Choose country`,
                //   });
                //   return;
                // }
                // if (!valData.currency) {
                //   notification.warning({
                //     message: `Please choose currency.`,
                //   });
                //   return;
                // }
                // if (!valData.stateName) {
                //   notification.warning({
                //     message: `Choose state`,
                //   });
                //   return;
                // }
                // if (!valData.cityName) {
                //   notification.warning({
                //     message: `Please Enter city`,
                //   });
                //   return;
                // }
                // if (!valData.postalCode) {
                //   notification.warning({
                //     message: `Please enter postal code`,
                //   });
                //   return;
                // }
                // if (!valData.companyAddress) {
                //   notification.warning({
                //     message: `Please enter company address`,
                //   });
                //   return;
                // }
                // /*if (!valData.website) {
                //             notification.warning({
                //                 message: `Please enter Website`,
                //             });
                //             return;
                //         }*/
                // if (!valData.password) {
                //   notification.warning({
                //     message: `Please enter password`,
                //   });
                //   return;
                // }

                valData.userType = "branch";
                if (valData.password.length < 6) {
                    notification.warning({
                        message: `Please enter atleast 6 digit password`,
                    });
                    return;
                }

                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (certificate && certificate.name) {
                    fd.append("certificate", certificate);
                }
                if (companyLogo && companyLogo.name) {
                    fd.append("companyLogo", companyLogo);
                }

                let x = await dispatch(AddBranchFxn(fd));
                if (!x.error) {
                    props.form.setFieldsValue({
                        contactPerson: "",
                        countryCode: undefined,
                        mobile: "",
                        companyAddress: "",
                        email: "",
                        country: "",
                        currency: undefined,
                        gstInNo: "",
                        cityName: "",
                        countryName: undefined,
                        password: "",
                        website: "",
                        gstRegistration: "",
                        stateName: "",
                        postalCode: "",
                        agentId: "",
                        referBy: "",
                        timezone: undefined,
                        timeFormat: undefined,
                        dateFormat: undefined,
                        assigntype: undefined,
                    });
                    setState(initialState);
                }
            } else {
            }
            // Add more fields with the same pattern for other required fields
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const name = e.target.name;
        const updatedFormData = {...formState};

        updatedFormData[name] = file;
        setState(updatedFormData);
    };

    const gstRegistrationOptions = [
        {option: "Yes", value: false},
        {option: "No", value: true},
    ];
    const RegistrationOptions = [
        {option: "Yes", value: true},
        {option: "No", value: false},
    ];

    let inputTypes = {
        fields: [
            //   {
            //     key: "companyName",
            //     label: "Company Name",
            //     required: true,
            //     placeholder: "Company Name",
            //     type: "text",
            //   },
            {
                key: "contactPerson",
                label: "Contact Person",
                showStar: true,
                showStar: true,
                placeholder: "Contact Person",
                type: "text",
            },
            {
                key: "email",
                label: "Company Email",
                required: false,
                showStar: true,
                placeholder: "Company Email",
                type: "text",
            },
            {
                key: "referBy",
                label: "Refer By",
                placeholder: "Refer By",
                type: "number",
            },
            // {
            //     key: "timezone",
            //     label: "Timezone",
            //     type: "select",
            //     required: false,
            //     showStar:true,
            //     options: TimeZones,
            //     showSearch: true,
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => `${x.name}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             timezone: x,
            //         });
            //     },
            // },
            // {
            //     key: "dateFormat",
            //     label: "Date Format",
            //     span: "col-md-3",
            //     type: "select",
            //     required: false,
            //     showStar:true,
            //     options: dateFormats,
            //     showSearch: true,
            //     keyAccessor: (x) => x,
            //     valueAccessor: (x) => `${x}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             dateFormat: x,
            //         });
            //     },
            // },
            // {
            //     key: "timeFormat",
            //     label: "Time Format",
            //     span: "col-md-3",
            //     type: "select",
            //     required: false,
            //     showStar:true,
            //     options: timeFormats,
            //     showSearch: true,
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => x.name,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             timeFormat: x,
            //         });
            //     },
            // },
            {
                key: "countryCode",
                placeholder: "Country Code",
                span: "col-md-2",
                type: "select",
                required: false,
                showStar: true,
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
            },
            {
                key: "mobile",
                span: "col-md-4",
                label: "Contact Number",
                required: false,
                showStar: true,
                placeholder: "Contact Number",
                type: "text",
            },
            {
                key: "countryName",
                label: "Country",
                required: false,
                showStar: true,
                type: "select",
                span: "col-md-3",
                showSearch: true,
                allowClear: true,
                options: Countries,
                placeholder: "Country Name",
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
            },
            {
                key: "currency",
                label: "Currency",
                span: "col-md-3",
                type: "select",
                required: false,
                showStar: true,
                options: CurrencyJson,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => x.name,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        currency: x,
                    });
                },
            },
            {
                key: "stateName",
                label: "State",
                required: false,
                showStar: true,
                type: "select",
                showSearch: true,
                allowClear: true,
                placeholder: "State Name",
                options: stateArray,
                keyAccessor: (x) => x.name,
                showStar: true,

                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateName: x,
                        cityName: "",
                    });
                    events.chooseState(x);
                },
            },

            {
                key: "cityName",
                label: "City",
                showStar: true,

                required: false,
                showStar: true,
                placeholder: "City",
                type: "text",
            },
            {
                key: "postalCode",
                label: "Postal Code",
                showStar: true,

                required: false,
                showStar: true,
                placeholder: "Postal Code",
                type: "text",
            },

            {
                key: "companyAddress",
                label: "Company Address(Optional)",
                required: false,
                placeholder: "Company Address",
                type: "text",
            },
            {
                key: "website",
                label: "Website(Optional)",
                placeholder: "Website",
                type: "text",
            },
            {
                key: "password",
                label: "Password",
                required: false,
                showStar: true,
                placeholder: "Password",
                type: "password",
            },

            {
                key: "gstRegistration",
                label: "GST Registration",
                options: gstRegistrationOptions,

                type: "select",
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    setLocalState({gstRegistration: x});
                },
            },

            {
                key: "gstInNo",
                label: "GSTIN Number",
                hidden: formState.gstRegistration,
                required: true,
                placeholder: "GSTIN Number",
                type: "text",
            },

            // {
            //   key: "enableForIp",
            //   label: "Enable For Ip Address",
            //   placeholder: "Enable For Ip Address",
            //   options: RegistrationOptions,

            //   type: "select",
            //   keyAccessor: (x) => x.value,
            //   valueAccessor: (x) => `${x.option}`,
            //   onChange: (x) => {
            //     props.form.setFieldsValue({
            //       enableForIp: x,
            //     });
            //   },
            // },
            // {
            //   key: "ipAddress",
            //   label: "Ip Address",
            //   hidden: !getFieldValue("enableForIp"),
            //   required: true,
            //   placeholder: "Please fill Ip Address",
            //   type: "text",
            // },
            // {
            //   label: "Company Logo",
            //   key: "companyLogo",
            //   type: "file",
            //   onChange: handleFileChange,
            // },a
            {
                label: "Certificate",
                key: "certificate",
                type: "file",
                fileName: formState.certificate,

                onChange: handleFileChange,
            },
            {
                label: "Profile Photo",
                key: "profilePhoto",
                type: "file",
                fileName: formState.profilePhoto,

                onChange: handleFileChange,
            },
        ],
    };

    return (
        <>
            <Card title={"Add Branch"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return !item.hidden ? (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                    </div>

                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className="mt-4 btn main-btn"
                    >
                        submit
                    </Button>
                </Form>
            </Card>
        </>
    );
};
export default Form.create()(AddBranch);
