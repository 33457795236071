import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DefaultTablePagination,
    Form,
    Modal,
    Popconfirm,
    Table,
    displayDate,
    notification,
} from "../../../components/Elements/appUtils";
import {imgPath} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    UpdateGicCommissionFxn,
    UpdateGicDocumentsFxn,
    gicCommissionListFxn,
} from "../action";

let {confirm} = Modal;
const GicActionComponent = (props) => {
    const [requestedList, setRequestedList] = useState([]);
    let {status, events} = props;
    return (
        <>
            {status == "Pending" ? (
                <>
                    <Popconfirm
                        title="Are you sure, you want to Submit this Gic?"
                        onConfirm={() => {
                            events.handleUpdateStatus(props._id, "Submitted");
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="btn" type="button">
                            <img src="../app/img/icons/check.svg" alt=""/>
                        </button>
                    </Popconfirm>
                </>
            ) : null}
            {status == "Submitted" ? (
                <>
                    <Popconfirm
                        title="Are you sure, you want to Verified this Gic?"
                        onConfirm={() => {
                            events.handleUpdateStatus(props._id, "Verified");
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="btn" type="button">
                            <img src="../app/img/icons/check.svg" alt=""/>
                        </button>
                    </Popconfirm>
                </>
            ) : null}
            {status == "Verified" ? (
                <>
                    <Popconfirm
                        title="Are you sure, you want to Approved this Gic?"
                        onConfirm={() => {
                            events.handleUpdateStatus(props._id, "Approved");
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="btn" type="button">
                            <img src="../app/img/icons/check.svg" alt=""/>
                        </button>
                    </Popconfirm>
                </>
            ) : null}
            {status == "Bank Verification Requested" ? (
                <>
                    <Popconfirm
                        title="Are you sure, you want to Approved this Gic?"
                        onConfirm={() => {
                            events.handleUpdateStatus(props._id, "Approved");
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="btn" type="button">
                            <img src="../app/img/icons/check.svg" alt=""/>
                        </button>
                    </Popconfirm>
                </>
            ) : null}
        </>
    );
};

const GicListComponent = (props) => {
    let dispatch = useDispatch();
    let tableRef = useRef();
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const [gicAccountFile, setGicAccountFile] = useState("");
    const [gicCertificate, setGicCertificate] = useState("");
    const [uploadDoucments, setUploadDoucments] = useState({
        open: false,
        gicId: "",
    });
    const events = {
        handleUpdateStatus: async (id, status) => {
            const { form } = props;
        
            form.validateFields(async (err, valData) => {
                if (!err) {
                    if (status === "Approved") {
                        valData.amount = 100;
                    }
        
                    valData.transactionType = "Credit";
                    valData.status = status;
                    valData.gicId = id;
                    let x = await dispatch(UpdateGicCommissionFxn(valData));
                    if (x && x.success) {
                        tableRef.current.reload();
                    }
                }
            });
        },
        apiRequest: (params) => {
            return new Promise(async (resolve) => {
                let {status} = props;
                let obj = {
                    sortField: "_id",
                    sortOrder: "descend",
                    ...params,
                };
                if (status) {
                    obj.status = status;
                } else {
                    obj.status = ["Pending", "Submitted", "Verified", "Bank verification requested"];
                }
                if (user.userType == "admin") {
                    obj.status = ["Pending", "Submitted", "Verified", "Bank verification requested", "Approved"];
                }
                let data = await dispatch(
                    gicCommissionListFxn({
                        ...obj,
                        regExFilters: ["type", "name"],
                    })
                );
                resolve(data);
            });
        },
        submitUpdate: (record) => {
            let {status} = record;
            let newStatus = "";
            let statusLabel = "";
            if (status == "Pending") {
                newStatus = "Submitted";
                statusLabel = "Submit";
            } else if (status == "Submitted") {
                newStatus = "Verified";
                statusLabel = "Verify";
            } else if (status == "Verified") {
                newStatus = "Bank verification requested.";
                statusLabel = "Bank verification requested.";
            } else if (status == "Bank verification requested.") {
                newStatus = "Approved";
                statusLabel = "Approve";
            }

            confirm({
                title: `Are you sure, you want to ${statusLabel} this Gic?`,
                onSubmit() {
                    events.handleUpdateStatus(record._id, newStatus);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
        showModal: (e, id) => {
            e.preventDefault();
            setUploadDoucments({
                open: e,
                gicId: id,
            });
        },
        onClose: () => {
            setUploadDoucments({
                open: false,
                gicId: "",
            });
        },
        handleSubmit: (e) => {
            const {form} = props;
            e.preventDefault();
            form.validateFields(async (err, valData) => {
                if (!err) {
                    if (!(gicAccountFile && gicAccountFile.name)) {
                        notification.warning({
                            message: "Choose select Gic-Accountfile",
                        });
                        return;
                    }
                    if (!(gicCertificate && gicCertificate.name)) {
                        notification.warning({
                            message: "Choose select Gic-Certificate",
                        });
                        return;
                    }

                    let fd = new FormData();
                    valData.gicId = uploadDoucments.gicId;
                    fd.append("obj", JSON.stringify(valData));
                    if (gicAccountFile && gicAccountFile.name) {
                        fd.append("gicAccountFile", gicAccountFile);
                    }

                    if (gicCertificate && gicCertificate.name) {
                        fd.append("gicCertificate", gicCertificate);
                    }
                    let x = await dispatch(UpdateGicDocumentsFxn(fd));
                    if (x && x.success) {
                        events.onClose();
                    }
                } else {
                    notification.warning({
                        message: `Something went wrong`,
                    });
                }
            });
        },
        handleFileChange: (e) => {
            let {name, files} = e;
            if (name === "gicCertificate") {
                const shortenedFileName =  
                    files[0].name.substring(
                        0,
                        6
                      ) +
                      "[...]" +
                      files[0].name.slice(-4);
                    
                setGicCertificate({
                    ...files[0],
                    name: shortenedFileName,
                });
            } else if (name === "gicAccountFile") {
                const shortenedFileName =  files[0].name.substring(
                    0,
                    6
                  ) +
                  "[...]" +
                  files[0].name.slice(-4);
                setGicAccountFile({
                    ...files[0],
                    name: shortenedFileName,
                });
            }
        },
    };

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (val, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 170,
            render: (item, record) => {
                let {status} = record;
                return (
                    <div style={{textAlign: "left"}}>
                        <div className={"mt-2"}>
                            {item == "Pending" ? (
                                <span className="pending">Pending</span>
                            ) : null}
                            {item == "Submitted" ? (
                                <span className="active">Submitted</span>
                            ) : null}
                            {item == "Verified" ? (
                                <span className="active">Verified</span>
                            ) : null}
                            {item == "Approved" ? (
                                <span className="active">Approved</span>
                            ) : null}
                            {item == "Bank verification requested." ? (
                                <span className="active">Bank verified.</span>
                            ) : null}
                            {item == "Disapproved" ? (
                                <span className="inactive">Cancelled</span>
                            ) : null}
                        </div>
                        {user.userType == "admin" &&
                        (status == "Pending" ||
                            status == "Submitted" ||
                            status == "Bank verification requested." ||
                            status == "Verified") ? (
                            <div className={"mt-2"}>
                                <a
                                    className={"btn"}
                                    onClick={() => {
                                        events.submitUpdate(record);
                                    }}
                                >
                                    Update Status
                                </a>
                            </div>
                        ) : null}
                    </div>
                );
            },
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            searchTextName: "firstName",
            filterRegex: true,
            width: 200,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            searchTextName: "lastName",
            filterRegex: true,
            width: 200,
        },
        {
            title: "Username",
            dataIndex: "userName",
            key: "userName",
            searchTextName: "userName",
            filterRegex: true,
            width: 200,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            searchTextName: "email",
            filterRegex: true,
            width: 200,
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
        },

        {
            title: "Security Question",
            dataIndex: "securityQuestion",
            key: "securityQuestion",
            render: (item, record) => {
                return (
                    <>
                        {item}
                        <br/>
                        <div>Ans : {record.securityAnswer}</div>
                    </>
                );
            },
        },
        {
            title: "College Name",
            dataIndex: "collegeName",
            key: "collegeName",
            searchTextName: "collegeName",
            filterRegex: true,
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            searchTextName: "city",
            filterRegex: true,
        },
        {
            title: "Contact No",
            dataIndex: "phoneNo",
            key: "phoneNo",
            searchTextName: "phoneNo",
            filterRegex: true,
        },
        {
            title: "Arrival Date",
            dataIndex: "expectedDateOfArrival",
            key: "expectedDateOfArrival",
            searchTextName: "expectedDateOfArrival",
            filterRegex: true,
            render: (text, record) => {
                return <>{text ? displayDate(text) : null} </>;
            },
        },
        {
            title: "Company Name",
            dataIndex: "companyId",
            key: "companyId",
            searchTextName: "companyId",
            filterRegex: true,
            render: (text, record) => {
                return <>{text && text.companyName ? text.companyName : null} </>;
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            hidden: user.userType == "admin",
            render: (item, record) => {
                let {status} = record;
                return (
                    <>
                        {status == "Verified" ? (
                            <>
                                <button
                                    className="btn"
                                    type="button"
                                    title="Upload Documents"
                                    onClick={(e) => events.showModal(e, record._id)}
                                >
                                    <img
                                        src={imgPath.check}
                                        className="btn-action"
                                        style={{width: "25px"}}
                                    />
                                </button>
                            </>
                        ) : null}
                    </>
                );
            },
        },
        /* {
                         title: "Action",
                         dataIndex: "action",
                         hidden: user.userType !== "admin",
                         fixed: 'right',
                         render: (val, record) => {
                             let {status} = record;
                             return (
                                 <>
                                     {(status == 'Pending' || status == 'Submitted' || status == 'Verified') ?
                                         <button className={'btn'} onClick={() => {
                                             events.submitUpdate(record)
                                         }}>
                                             <img src={imgPath.check}/>
                                         </button> : null}
                                 </>
                             )
                         }
                     }*/
    ];

    let loadColumn = () => {
        let columnList = [];
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item);
            }
        });
        return columnList;
    };
    let inputTypes = {
        fields: [
            {
                key: "gicAccountFile",
                label: "GIC Account File",
                required: false,
                placeholder: "GIC Account File",
                type: "file",
                fileName: gicAccountFile,
                onChange: ({target}) => {
                    events.handleFileChange(target);
                },
                span: "col-md-10",
            },

            {
                key: "gicCertificate",
                label: "GIC Certificate",
                required: false,
                placeholder: "GIC Certificate",
                type: "file",
                fileName: gicCertificate,
                onChange: ({target}) => {
                    events.handleFileChange(target);
                },
                span: "col-md-10",
            },
        ],
    };
    return (
        <>
            <Table
                apiRequest={events.apiRequest}
                columns={loadColumn()}
                size="large"
                pagination={DefaultTablePagination()}
                extraProps={{scroll: {x: 2200}}}
                ref={tableRef}
            />
            <Modal
                visible={uploadDoucments.open}
                onClose={events.onClose}
                open={uploadDoucments.open}
                width={"40%"}
                placement="right"
                // height={"60%"}
                title={"Upload Files"}
            >
                <div>
                    <Form onSubmit={events.handleSubmit}>
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <div
                                        className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                        key={key}
                                    >
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                );
                            })}
                        </div>

                        <div className={"mt-4"}>
                            <Button type={"primary"} htmlType="submit">
                                submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default Form.create()(GicListComponent);
