import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../../components/Elements/appUtils";
import {EnglishExamOptions} from "../../../components/_utils/_utils";
import {Flags} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {branchListFxn} from "../../Reception/actions";
import {GetRelatedUserFxn, postUpdateUserFxn} from "./action";

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const EditEnglishTeacher = (props) => {
    const [branchList, setBranchList] = useState([]);
    const [enableForEnglish, setEnableForEnglish] = useState(false);
    const dispatch = useDispatch();
    let [userType, setUserType] = useState("englishTeacher");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const [userId, setUserId] = useState("");

    useEffect(() => {
        loadBranches();
    }, []);
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setUserId(id);

            getRelatedUser(id);
        }
    }, [branchList]);
    const getRelatedUser = async (id) => {
        let {data} = await dispatch(GetRelatedUserFxn(id));

        setTimeout(() => {
            if (data && data.englishExamType && data.englishExamType.length > 0) {
                setEnableForEnglish(!enableForEnglish);
            }
            props.form.setFieldsValue({
                name: data.name,
                address: data.address,
                email: data.email,
                countryCode: data.countryISOCode,
                mobile: data.mobile,
                englishExamType: data.englishExamType,
                countries: data.countries,
                enableForIp: data.enableForIp,
            });
        }, 500);
        events.setBranch(data.branchId);
    };
    const events = {
        setBranch: (id) => {
            let branchUser = _.find(branchList, (item) => {
                return item._id == id;
            });
            if (branchUser) {
                props.form.setFieldsValue({
                    branchId: branchUser._id,
                });
            }
        },
    };
    const loadBranches = async () => {
        let {data} = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }

                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }
                if (user.userType == "headBranch") {
                    if (!valData.branchId) {
                        notification.warning({message: "Choose branch"});
                        return;
                    }
                }

                // if (!valData.countries || (valData.countries && !valData.countries.length)) {
                //     notification.warning({message: "Choose country"})
                //     return
                // }
                if (
                    enableForEnglish &&
                    (!valData.englishExamType ||
                        (valData.englishExamType && !valData.englishExamType.length))
                ) {
                    notification.warning({message: "Choose english test"});
                    return;
                }
                valData.id = userId;

                let x = await dispatch(
                    postUpdateUserFxn({
                        ...valData,
                        userType: userType,
                        enableForEnglish,
                    })
                );
                if (x.success) {
                    setEnableForEnglish(false);
                }
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: " Full Name",
                required: false,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "email",
                label: "Email",
                required: false,
                placeholder: "Email",
                type: "text",
            },

            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-3",
            },
            {
                key: "mobile",
                label: "Mobile No",
                required: false,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-3",
            },

            {
                key: "branchId",
                label: "Branch",
                hidden: !(user.userType == "headBranch"),
                placeholder: "Please Choose Branch",
                type: "select",
                options: branchList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        branchId: x,
                    });
                },
            },
            {
                key: "enableForIp",
                label: "Enable For Ip Address",
                placeholder: "Enable For Ip Address",
                options: RegistrationOptions,
                type: "select",
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        enableForIp: x,
                    });
                },
            },
        ],
        counsellorField: [
            {
                key: "englishExamType",
                label: "English Test",
                placeholder: "Please Choose English Test",
                type: "select",
                options: EnglishExamOptions,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        englishExamType: x,
                    });
                },
            },
        ],
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Edit English Teacher"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Card>
                <Card title={"Choose User Type"}>
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="form-group">
                                <div className="radio-check-box">
                                    <input
                                        type="checkbox"
                                        value={"englishTeacher"}
                                        name="english"
                                        id="english"
                                        checked={true}
                                    />
                                    <label className="form-control">
                                        Enable user for English Test <span>Click to Select</span>
                                    </label>
                                    <img src="../app/img/check-green.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        {inputTypes.counsellorField.map((item, key) => {
                            return (
                                <div className={`pt-3 col-md-6`} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Card>
            </Form>
        </>
    );
};

export default Form.create()(EditEnglishTeacher);
